"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
class RingsizeCtrl {
    constructor($scope, $timeout, $filter) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.unit = JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{}').unit || 'metric';
        this.inside = JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{}').inside || 'diameter';
        this.data = {};
        this.titles = {
            usa: [
                {
                    title: this.$filter('translate')('USA'),
                    code: 'US'
                },
                {
                    title: this.$filter('translate')('Canada'),
                    code: 'CA'
                },
                {
                    title: this.$filter('translate')('Mexico'),
                    code: 'MX'
                },
            ],
            russia: [
                {
                    title: this.$filter('translate')('Russia'),
                    code: 'RU'
                },
                {
                    title: this.$filter('translate')('Ukraine'),
                    code: 'UA'
                },
                {
                    title: this.$filter('translate')('Germany'),
                    code: 'DE'
                },
            ],
            england: [
                {
                    title: this.$filter('translate')('United Kingdom'),
                    code: 'UK'
                },
                {
                    title: this.$filter('translate')('Australia'),
                    code: 'AU'
                },
                {
                    title: this.$filter('translate')('South Africa'),
                    code: 'ZA'
                },
            ],
            china: [
                {
                    title: this.$filter('translate')('China'),
                    code: 'CN'
                },
                {
                    title: this.$filter('translate')('Japan'),
                    code: 'JP'
                },
                {
                    title: this.$filter('translate')('Turkey'),
                    code: 'TR'
                },
                {
                    title: this.$filter('translate')('Israel'),
                    code: 'IL'
                },
            ],
            italy: [
                {
                    title: this.$filter('translate')('Italy'),
                    code: 'IT'
                },
                {
                    title: this.$filter('translate')('Spain'),
                    code: 'ES'
                },
                {
                    title: this.$filter('translate')('Netherlands'),
                    code: 'NL'
                },
            ],
        };
        this.sizes = [
            {
                "diameter": { "metric": 12.04, "imperial": 0.474 },
                "circumference": { "metric": 37.8, "imperial": 1.49 },
                "sizes": { "usa": "½", "england": "A", "russia": undefined, "china": undefined, "italy": undefined }
            }, {
                "diameter": { "metric": 12.45, "imperial": 0.49 },
                "circumference": { "metric": 39.1, "imperial": 1.54 },
                "sizes": { "usa": 1, "england": "B", "russia": undefined, "china": 1, "italy": undefined }
            }, {
                "diameter": { "metric": 12.85, "imperial": 0.506 },
                "circumference": { "metric": 40.4, "imperial": 1.59 },
                "sizes": { "usa": "1 ½", "england": "C", "russia": undefined, "china": undefined, "italy": "½" }
            }, {
                "diameter": { "metric": 13.06, "imperial": 0.514 },
                "circumference": { "metric": 41, "imperial": 1.61 },
                "sizes": { "usa": "1 ¾", "england": "C ½", "russia": undefined, "china": undefined, "italy": 1 }
            }, {
                "diameter": { "metric": 13.26, "imperial": 0.522 },
                "circumference": { "metric": 41.7, "imperial": 1.64 },
                "sizes": { "usa": 2, "england": "D", "russia": 13, "china": 2, "italy": "1 ¾" }
            }, {
                "diameter": { "metric": 13.46, "imperial": 0.53 },
                "circumference": { "metric": 42.3, "imperial": 1.67 },
                "sizes": { "usa": "2 ¼", "england": "D ½", "russia": "13 ¼", "china": undefined, "italy": "2 ¼" }
            }, {
                "diameter": { "metric": 13.67, "imperial": 0.538 },
                "circumference": { "metric": 42.9, "imperial": 1.69 },
                "sizes": { "usa": "2 ½", "england": "E", "russia": "13 ½", "china": 3, "italy": 3 }
            }, {
                "diameter": { "metric": 13.87, "imperial": 0.546 },
                "circumference": { "metric": 43.6, "imperial": 1.72 },
                "sizes": { "usa": "2 ¾", "england": "E ½", "russia": "13 ¾", "china": undefined, "italy": "3 ½" }
            }, {
                "diameter": { "metric": 14.07, "imperial": 0.554 },
                "circumference": { "metric": 44.2, "imperial": 1.74 },
                "sizes": { "usa": 3, "england": "F", "russia": 14, "china": 4, "italy": 4 }
            }, {
                "diameter": { "metric": 14.27, "imperial": 0.562 },
                "circumference": { "metric": 44.8, "imperial": 1.77 },
                "sizes": { "usa": "3 ¼", "england": "F ½", "russia": "14 ¼", "china": 5, "italy": "4 ¾" }
            }, {
                "diameter": { "metric": 14.48, "imperial": 0.57 },
                "circumference": { "metric": 45.5, "imperial": 1.79 },
                "sizes": { "usa": "3 ½", "england": "G", "russia": "14 ½", "china": undefined, "italy": "5 ½" }
            }, {
                "diameter": { "metric": 14.68, "imperial": 0.578 },
                "circumference": { "metric": 46.1, "imperial": 1.82 },
                "sizes": { "usa": "3 ¾", "england": "G ½", "russia": "14 ¾", "china": 6, "italy": 6 }
            }, {
                "diameter": { "metric": 14.88, "imperial": 0.586 },
                "circumference": { "metric": 46.8, "imperial": 1.84 },
                "sizes": { "usa": 4, "england": "H", "russia": 15, "china": 7, "italy": "6 ¾" }
            }, {
                "diameter": { "metric": 15.09, "imperial": 0.594 },
                "circumference": { "metric": 47.4, "imperial": 1.87 },
                "sizes": { "usa": "4 ¼", "england": "H ½", "russia": undefined, "china": undefined, "italy": "7 ½" }
            }, {
                "diameter": { "metric": 15.29, "imperial": 0.602 },
                "circumference": { "metric": 48, "imperial": 1.89 },
                "sizes": { "usa": "4 ½", "england": "I", "russia": "15 ½", "china": 8, "italy": 8 }
            }, {
                "diameter": { "metric": 15.49, "imperial": 0.61 },
                "circumference": { "metric": 48.7, "imperial": 1.92 },
                "sizes": { "usa": "4 ¾", "england": "J", "russia": undefined, "china": undefined, "italy": "8 ¾" }
            }, {
                "diameter": { "metric": 15.7, "imperial": 0.618 },
                "circumference": { "metric": 49.3, "imperial": 1.94 },
                "sizes": { "usa": 5, "england": "J ½", "russia": "15 ¾", "china": 9, "italy": "9 ¼" }
            }, {
                "diameter": { "metric": 15.9, "imperial": 0.626 },
                "circumference": { "metric": 50, "imperial": 1.97 },
                "sizes": { "usa": "5 ¼", "england": "K", "russia": 16, "china": undefined, "italy": 10 }
            }, {
                "diameter": { "metric": 16.1, "imperial": 0.634 },
                "circumference": { "metric": 50.6, "imperial": 1.99 },
                "sizes": { "usa": "5 ½", "england": "K ½", "russia": "16 ¼", "china": 10, "italy": "10 ½" }
            }, {
                "diameter": { "metric": 16.31, "imperial": 0.642 },
                "circumference": { "metric": 51.2, "imperial": 2.02 },
                "sizes": { "usa": "5 ¾", "england": "L", "russia": undefined, "china": undefined, "italy": "11 ½" }
            }, {
                "diameter": { "metric": 16.51, "imperial": 0.65 },
                "circumference": { "metric": 51.9, "imperial": 2.04 },
                "sizes": { "usa": 6, "england": "L ½", "russia": "16 ½", "china": 11, "italy": "11 ¾" }
            }, {
                "diameter": { "metric": 16.71, "imperial": 0.658 },
                "circumference": { "metric": 52.5, "imperial": 2.07 },
                "sizes": { "usa": "6 ¼", "england": "M", "russia": "16 ¾", "china": 12, "italy": "12 ½" }
            }, {
                "diameter": { "metric": 16.92, "imperial": 0.666 },
                "circumference": { "metric": 53.1, "imperial": 2.09 },
                "sizes": { "usa": "6 ½", "england": "M ½", "russia": undefined, "china": 13, "italy": "13 ¼" }
            }, {
                "diameter": { "metric": 17.12, "imperial": 0.674 },
                "circumference": { "metric": 53.8, "imperial": 2.12 },
                "sizes": { "usa": "6 ¾", "england": "N", "russia": 17, "china": undefined, "italy": "13 ¾" }
            }, {
                "diameter": { "metric": 17.32, "imperial": 0.682 },
                "circumference": { "metric": 54.4, "imperial": 2.14 },
                "sizes": { "usa": 7, "england": "N ½", "russia": "17 ¼", "china": 14, "italy": 14.5 }
            }, {
                "diameter": { "metric": 17.53, "imperial": 0.69 },
                "circumference": { "metric": 55.1, "imperial": 2.17 },
                "sizes": { "usa": "7 ¼", "england": "O", "russia": "17 ½", "china": undefined, "italy": 15 }
            }, {
                "diameter": { "metric": 17.73, "imperial": 0.698 },
                "circumference": { "metric": 55.7, "imperial": 2.19 },
                "sizes": { "usa": "7 ½", "england": "O ½", "russia": "17 ¾", "china": 15, "italy": "15 ¾" }
            }, {
                "diameter": { "metric": 17.93, "imperial": 0.706 },
                "circumference": { "metric": 56.3, "imperial": 2.22 },
                "sizes": { "usa": "7 ¾", "england": "P", "russia": undefined, "china": undefined, "italy": "16 ¾" }
            }, {
                "diameter": { "metric": 18.14, "imperial": 0.714 },
                "circumference": { "metric": 57, "imperial": 2.24 },
                "sizes": { "usa": 8, "england": "P ½", "russia": 18, "china": 16, "italy": 17 }
            }, {
                "diameter": { "metric": 18.34, "imperial": 0.722 },
                "circumference": { "metric": 57.6, "imperial": 2.27 },
                "sizes": { "usa": "8 ¼", "england": "Q", "russia": "18 ¼", "china": undefined, "italy": "17 ½" }
            }, {
                "diameter": { "metric": 18.54, "imperial": 0.73 },
                "circumference": { "metric": 58.3, "imperial": 2.29 },
                "sizes": { "usa": "8 ½", "england": "Q ½", "russia": "18 ½", "china": 17, "italy": "17 ¾" }
            }, {
                "diameter": { "metric": 18.75, "imperial": 0.738 },
                "circumference": { "metric": 58.9, "imperial": 2.32 },
                "sizes": { "usa": "8 ¾", "england": "R", "russia": "18 ¾", "china": undefined, "italy": 19 }
            }, {
                "diameter": { "metric": 18.95, "imperial": 0.746 },
                "circumference": { "metric": 59.5, "imperial": 2.34 },
                "sizes": { "usa": 9, "england": "R ½", "russia": 19, "china": 18, "italy": "19 ½" }
            }, {
                "diameter": { "metric": 19.15, "imperial": 0.754 },
                "circumference": { "metric": 60.2, "imperial": 2.37 },
                "sizes": { "usa": "9 ¼", "england": "S", "russia": undefined, "china": undefined, "italy": "20 ¼" }
            }, {
                "diameter": { "metric": 19.35, "imperial": 0.762 },
                "circumference": { "metric": 60.8, "imperial": 2.39 },
                "sizes": { "usa": "9 ½", "england": "S ½", "russia": "19 ¼", "china": 19, "italy": "20 ¾" }
            }, {
                "diameter": { "metric": 19.56, "imperial": 0.77 },
                "circumference": { "metric": 61.4, "imperial": 2.42 },
                "sizes": { "usa": "9 ¾", "england": "T", "russia": undefined, "china": undefined, "italy": "21 ½" }
            }, {
                "diameter": { "metric": 19.76, "imperial": 0.778 },
                "circumference": { "metric": 62.1, "imperial": 2.44 },
                "sizes": { "usa": 10, "england": "T ½", "russia": 20, "china": 20, "italy": 22 }
            }, {
                "diameter": { "metric": 19.96, "imperial": 0.786 },
                "circumference": { "metric": 62.7, "imperial": 2.47 },
                "sizes": { "usa": "10 ¼", "england": "U", "russia": undefined, "china": 21, "italy": "22 ¾" }
            }, {
                "diameter": { "metric": 20.17, "imperial": 0.794 },
                "circumference": { "metric": 63.4, "imperial": 2.49 },
                "sizes": { "usa": "10 ½", "england": "U ½", "russia": "20 ¼", "china": 22, "italy": "23 ¾" }
            }, {
                "diameter": { "metric": 20.37, "imperial": 0.802 },
                "circumference": { "metric": 64, "imperial": 2.52 },
                "sizes": { "usa": "10 ¾", "england": "V", "russia": undefined, "china": undefined, "italy": 24 }
            }, {
                "diameter": { "metric": 20.57, "imperial": 0.81 },
                "circumference": { "metric": 64.6, "imperial": 2.54 },
                "sizes": { "usa": 11, "england": "V ½", "russia": "20 ¾", "china": 23, "italy": "24 ¾" }
            }, {
                "diameter": { "metric": 20.78, "imperial": 0.818 },
                "circumference": { "metric": 65.3, "imperial": 2.57 },
                "sizes": { "usa": "11 ¼", "england": "W", "russia": undefined, "china": undefined, "italy": "25 ¼" }
            }, {
                "diameter": { "metric": 20.98, "imperial": 0.826 },
                "circumference": { "metric": 65.9, "imperial": 2.59 },
                "sizes": { "usa": "11 ½", "england": "W ½", "russia": 21, "china": 24, "italy": 26 }
            }, {
                "diameter": { "metric": 21.18, "imperial": 0.834 },
                "circumference": { "metric": 66.6, "imperial": 2.62 },
                "sizes": { "usa": "11 ¾", "england": "X", "russia": "21 ¼", "china": undefined, "italy": "26 ½" }
            }, {
                "diameter": { "metric": 21.39, "imperial": 0.842 },
                "circumference": { "metric": 67.2, "imperial": 2.65 },
                "sizes": { "usa": 12, "england": "Y", "russia": "21 ½", "china": 25, "italy": "27 ¼" }
            }, {
                "diameter": { "metric": 21.59, "imperial": 0.85 },
                "circumference": { "metric": 67.8, "imperial": 2.67 },
                "sizes": { "usa": "12 ¼", "england": "Y ½", "russia": undefined, "china": undefined, "italy": "27 ¾" }
            }, {
                "diameter": { "metric": 21.79, "imperial": 0.858 },
                "circumference": { "metric": 68.5, "imperial": 2.7 },
                "sizes": { "usa": "12 ½", "england": "Z", "russia": "21 ¾", "china": 26, "italy": "28 ½" }
            }, {
                "diameter": { "metric": 22, "imperial": 0.866 },
                "circumference": { "metric": 69.1, "imperial": 2.72 },
                "sizes": { "usa": "12 ¾", "england": "Z ½", "russia": undefined, "china": undefined, "italy": 29 }
            }, {
                "diameter": { "metric": 22.2, "imperial": 0.874 },
                "circumference": { "metric": 69.7, "imperial": 2.75 },
                "sizes": { "usa": 13, "england": undefined, "russia": 22, "china": 27, "italy": "29 ¾" }
            }, {
                "diameter": { "metric": 22.4, "imperial": 0.882 },
                "circumference": { "metric": 70.4, "imperial": 2.77 },
                "sizes": { "usa": "13 ¼", "england": "Z 1", "russia": undefined, "china": undefined, "italy": "30 ½" }
            }, {
                "diameter": { "metric": 22.61, "imperial": 0.89 },
                "circumference": { "metric": 71, "imperial": 2.8 },
                "sizes": { "usa": "13 ½", "england": undefined, "russia": "22 ½", "china": undefined, "italy": 31 }
            }, {
                "diameter": { "metric": 22.81, "imperial": 0.898 },
                "circumference": { "metric": 71.7, "imperial": 2.82 },
                "sizes": { "usa": "13 ¾", "england": "Z 2", "russia": undefined, "china": undefined, "italy": "31 ¾" }
            }, {
                "diameter": { "metric": 23.01, "imperial": 0.906 },
                "circumference": { "metric": 72.3, "imperial": 2.85 },
                "sizes": { "usa": 14, "england": "Z 3", "russia": 23, "china": undefined, "italy": "32 ¼" }
            }, {
                "diameter": { "metric": 23.22, "imperial": 0.914 },
                "circumference": { "metric": 72.9, "imperial": 2.87 },
                "sizes": { "usa": "14 ¼", "england": undefined, "russia": undefined, "china": undefined, "italy": 33 }
            }, {
                "diameter": { "metric": 23.42, "imperial": 0.922 },
                "circumference": { "metric": 73.6, "imperial": 2.9 },
                "sizes": { "usa": "14 ½", "england": "Z 4", "russia": "23 ½", "china": undefined, "italy": "33 ½" }
            }, {
                "diameter": { "metric": 23.62, "imperial": 0.93 },
                "circumference": { "metric": 74.2, "imperial": 2.92 },
                "sizes": { "usa": "14 ¾", "england": undefined, "russia": undefined, "china": undefined, "italy": "34 ¼" }
            }, {
                "diameter": { "metric": 23.83, "imperial": 0.938 },
                "circumference": { "metric": 74.8, "imperial": 2.95 },
                "sizes": { "usa": 15, "england": "Z 5", "russia": "23 ¾", "china": undefined, "italy": "34 ¾" }
            }, {
                "diameter": { "metric": 24.03, "imperial": 0.946 },
                "circumference": { "metric": 75.5, "imperial": 2.97 },
                "sizes": { "usa": "15 ¼", "england": undefined, "russia": undefined, "china": undefined, "italy": "35 ½" }
            }, {
                "diameter": { "metric": 24.23, "imperial": 0.954 },
                "circumference": { "metric": 76.1, "imperial": 3 },
                "sizes": { "usa": "15 ½", "england": "Z 6", "russia": "24 ¼", "china": undefined, "italy": "36 ¼" }
            }, {
                "diameter": { "metric": 24.43, "imperial": 0.962 },
                "circumference": { "metric": 76.8, "imperial": 3.02 },
                "sizes": { "usa": "15 ¾", "england": undefined, "russia": undefined, "china": undefined, "italy": "36 ¾" }
            }, {
                "diameter": { "metric": 24.64, "imperial": 0.97 },
                "circumference": { "metric": 77.4, "imperial": 3.05 },
                "sizes": { "usa": 16, "england": undefined, "russia": "24 ½", "china": undefined, "italy": "37 ½" }
            }
        ];
        this.limits = {
            diameter: {
                metric: {},
                imperial: {},
            },
            circumference: {
                metric: {},
                imperial: {},
            },
        };
        this.$scope.$watch('$ctrl.unit', (unit) => {
            let options = JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{}');
            localStorage.setItem(`${this.constructor.name}_options`, JSON.stringify(Object.assign(Object.assign({}, options), { unit })));
        });
        this.$scope.$watch('$ctrl.inside', (inside) => {
            console.log(inside);
            let options = JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{}');
            localStorage.setItem(`${this.constructor.name}_options`, JSON.stringify(Object.assign(Object.assign({}, options), { inside })));
        });
        const metricDiameterItems = this.sizes.map((item) => ng.isNumber(item.diameter.metric) ? item.diameter.metric : undefined)
            .filter((item) => item != undefined);
        const imperialDiameterItems = this.sizes.map((item) => ng.isNumber(item.diameter.imperial) ? item.diameter.imperial : undefined)
            .filter((item) => item != undefined);
        const metricCircumferenceItems = this.sizes.map((item) => ng.isNumber(item.circumference.metric) ? item.circumference.metric : undefined)
            .filter((item) => item != undefined);
        const imperialCircumferenceItems = this.sizes.map((item) => ng.isNumber(item.circumference.imperial) ? item.circumference.imperial : undefined)
            .filter((item) => item != undefined);
        this.limits = {
            diameter: {
                metric: {
                    min: Math.min(...metricDiameterItems),
                    max: Math.max(...metricDiameterItems),
                },
                imperial: {
                    min: Math.min(...imperialDiameterItems),
                    max: Math.max(...imperialDiameterItems),
                },
            },
            circumference: {
                metric: {
                    min: Math.min(...metricCircumferenceItems),
                    max: Math.max(...metricCircumferenceItems),
                },
                imperial: {
                    min: Math.min(...imperialCircumferenceItems),
                    max: Math.max(...imperialCircumferenceItems),
                },
            },
        };
        console.log(this.limits);
    }
    $onInit() {
    }
    setUnit(unit) {
        this.unit = unit;
        this.clear(this.ringsizeForm);
    }
    setInside(inside) {
        this.inside = inside;
        this.clear(this.ringsizeForm);
    }
    submitForm($form) {
        if ($form.$valid) {
            this.result = {};
            try {
                let sizes = this.sizes.filter((item) => {
                    const value = item[this.inside][this.unit];
                    if (ng.isNumber(value)) {
                        return value <= this.data[this.inside];
                    }
                    return false;
                });
                console.log(sizes);
                if (sizes.length > 0) {
                    this.result.table = [];
                    Object.keys(this.titles).forEach((key) => {
                        const title = this.titles[key].map((item) => `${item.title} (${item.code})`).join(', ');
                        let value = undefined;
                        let sizesCycle = ng.copy(sizes);
                        while (sizesCycle.length > 0) {
                            const size = sizesCycle.pop();
                            value = size.sizes[key];
                            if (value != undefined) {
                                break;
                            }
                        }
                        this.result.table.push([
                            title, value
                        ]);
                    });
                    console.log(this.result.table);
                }
                else {
                    throw new Error("Missing result");
                }
            }
            catch (e) {
                this.result = { error: this.$filter('translate')('Error') };
                console.error(e);
            }
        }
    }
    clear($form) {
        if ($form != undefined) {
            $form.$setPristine();
            $form.$setUntouched();
        }
        this.data = {};
        this.result = undefined;
    }
}
RingsizeCtrl.$inject = ['$scope', '$timeout', '$filter'];
const appModule = ng.module('app');
appModule.component('gameRingsize', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: RingsizeCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('ringsize/');
    }]);
